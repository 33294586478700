.buttonWrapper {
  border-radius: 6px;
  display: flex !important;
  align-items: center;
  font-weight: 500;
  line-height: 10px;
  justify-content: center;
  height: 37px !important;

  .name {
    font-weight: 700;
    line-height: 14px;
  }

  .icon {
    svg {
      height: 18px;
      width: 18px;
    }
  }

  .iconLarge svg {
    height: 18px;
    width: 18px;
  }
}

.disable-btn {
  border: 2px solid #8e8e93 !important;
  span {
    color: #8e8e93 !important;
    svg line {
      stroke: #8e8e93 !important;
    }
    svg g path {
      fill: #8e8e93 !important;
    }
  }
}

.disable {
  box-shadow: none !important;
  cursor: not-allowed;

  &:not(.icon svg path) {
    opacity: 0.7;
  }

  .icon svg path {
    &[fill] {
      fill: #ced4da;
    }

    &[stroke] {
      stroke: #ced4da;
    }
  }
}

@mixin btnType($backgroundColor, $borderColor, $color, $hoverBackground, $hoverBorderColor, $hoverColor) {
  background: $backgroundColor !important;
  border-color: $borderColor !important;
  color: $color !important;

  &:hover,
  &:focus {
    background: $hoverBackground !important;
    border-color: $hoverBorderColor !important;
    color: $hoverColor !important;
    span {
      color: $hoverColor !important;
    }
  }
  span {
    color: $color !important;
  }
}

.linear {
  @include btnType(
    linear-gradient(90deg, #154398 0%, #ed1117 100%),
    #ffffff,
    #ffffff,
    linear-gradient(90deg, #154398 0%, #ed1117 100%),
    #ffffff,
    #ffffff
  );
}

.greenLeaves {
  @include btnType(
    #7fca27,
    #ffffff,
    #ffffff,
    #ffffff,
    #ffffff,
    #ffffff
  );
  &:hover {
    background: #9acf1c !important;
    color: #ffffff !important;
  }
}

.primary {
  @include btnType(#0a8bc2, #138fc4, #ffffff, #0a8ec7, #81d9ff, #ffffff);

  svg path {
    stroke: #ffffff;
    fill: #ffffff;
  }
  & {
    border: 2px solid;
  }
}

.primary1 {
  @include btnType(#01638d, #01638d, #ffffff, #01638d, #81d9ff, #ffffff);

  svg path {
    stroke: #ffffff;
    fill: #ffffff;
  }
  & {
    border: 2px solid;
  }
}

.primary-no-hover {
  @include btnType(#01638d, #01638d, #ffffff, #01638d, #01638d, #ffffff);
}

.disable-style {
  @include btnType(
    rgba(242, 242, 242, 0.9),
    rgba(142, 142, 147, 1),
    rgba(142, 142, 147, 1),
    rgba(242, 242, 242, 0.9),
    rgba(142, 142, 147, 1),
    rgba(142, 142, 147, 1)
  );
  svg line {
    stroke: rgb(128, 128, 133) !important;
  }
  svg g path {
    fill: rgb(128, 128, 133) !important;
    stroke: rgb(128, 128, 133) !important;
  }
}

.yellow {
  @include btnType(#ffd800, #ffd800, #172b4d, #ffd800, #ffd800, #172b4d);
}

.secondary {
  @include btnType(#b6bcc4, #b6bcc4, #ffffff, #b6bcc4, #b6bcc4, #ffffff);
  font-weight: 700 !important;
}

.closeVND {
  @include btnType(#777779, #777779, #ffffff, #777779, #777779, #ffffff);
}

.green {
  @include btnType(#ffffff, #0d99ff, #0d99ff, #ffffff, #0d99ff, #0d99ff);
}

.third {
  @include btnType(#ffffff, #01638d, #01638d, rgba(1, 99, 141, 0.08), #01638d, #01638d);
}

.third-custom {
  @include btnType(#ffffff, #28265b, #28265b, #28265b, #28265b, #28265b);
}

.red-style {
  @include btnType(#ffffff, #d63939, #d63939, rgba(206, 49, 53, 0.08), #d63939, #d63939);
}

.green-style {
  @include btnType(#ffffff, #0d9d57, #0d9d57, rgba(13, 157, 87, 0.08), #0d9d57, #0d9d57);
}

.red-st {
  @include btnType(#ffffff, #d63939, #d63939, #abb2b8, #abb2b8, #ffffff);

  &:hover,
  &:focus {
    svg path {
      stroke: white;
    }
  }
}

.gray-style {
  @include btnType(#f1f3f5, #f1f3f5, #3e4554, #cccccc, #cccccc, #3e4554);
}

.third-st {
  @include btnType(#ffffff, #154398, #154398, #0e8cf1, #0e8cf1, #ffffff);

  &:hover,
  &:focus {
    svg path {
      stroke: white;
    }
  }
}

.fourth {
  @include btnType(#2c2c35, #c4c4c4, #c4c4c4, #2c2c35, #c4c4c4, #c4c4c4);
}

.fifth {
  @include btnType(#ffffff, #8b8a92, #8b8a92, #ffffff, #8b8a92, #8b8a92);
}

.orange {
  @include btnType(#f7941e, #f7941e, #ffffff, #f7941e, #f7941e, #ffffff);
}

.back {
  @include btnType(#f0f0f0, #505050, #505050, #f0f0f0, #505050, #505050);
}

.primaryFilter {
  @include btnType(#0d99ff, #0d99ff, #ffffff, #0d99ff, #0d99ff, #ffffff);

  .icon svg {
    height: 24px;
    width: 24px;
  }

  height: 40px !important;
  width: 40px !important;
}

.dark {
  @include btnType(#757575, #757575, #ffffff, #757575, #757575, #ffffff);
}

.primary-sm {
  @include btnType(#d3f7ff, #d3f7ff, #0d99ff, #d3f7ff, #d3f7ff, #0d99ff);
}

.danger {
  @include btnType(#ffffff, #e50500, #e50500, #e50500, #e50500, #ffffff);

  &:hover {
    background: #e50500 !important;
    color: #fff !important;
  }
}

.red {
  @include btnType(#e50500, #ffffff, #ffffff, #ffffff, #e50500, #e50500);

  &:hover {
    svg path {
      fill: e50500;
    }
  }
}

.svg-24 {
  .icon svg {
    height: 24px;
    width: 24px;
  }
}

.ButtonCustom {
  @include btnType(#e50500, #ffffff, #ffffff, #ffffff, #e50500, #e50500);
  
  border-radius: 32px !important;
  border: none !important;
  box-shadow: none !important;
  padding-left: 4px; 
  justify-content: flex-end !important; 

  .icon {
    display: flex; 
    align-items: center; 
    justify-content: center; 
    margin-left: 8px; 
  }

  &:hover {
    background: #fff !important;
    color: #e50500 !important;
  }
}

.svg-18 {
  border-radius: 50% !important;
  height: 18px !important;
  width: 18px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
